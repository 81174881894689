import React from 'react';

function Institucional() {
    return (
        <div className="container" style={{ minHeight: '45vh', padding: '20px' }}>
            <h1>Institucional</h1>
            <p>Bem-vindo à nossa página Institucional. Aqui você encontrará informações sobre a nossa empresa, nossa história, missão, visão e valores.</p>
            <p>Somos uma empresa dedicada a fornecer os melhores produtos e serviços para nossos clientes.</p>
            <p>Nossa missão é oferecer qualidade e excelência em tudo o que fazemos.</p>
        </div>
    );
}

export default Institucional;
