import { call, put, all, takeLatest } from 'redux-saga/effects';
import { buscarProdutosSuccess, buscarProdutosError, buscarProdutosGiroSuccess, buscarProdutosGiroError } from './actions';
import axios from 'axios';

function* buscarProdutos({ filtro }) {
    console.log('filtro de produtos no sagas => ', filtro)
    try {
        const queryParams = new URLSearchParams(); // Crie um novo objeto URLSearchParams

        // Adicione cada campo do filtro como um parâmetro de consulta, se existir
        if (filtro) {
            if (filtro.codprod) {
                queryParams.append('codprod', filtro.codprod);
            }
            if (filtro.descricao) {
                queryParams.append('descricao', filtro.descricao);
            }
            if (filtro.codepto) {
                queryParams.append('codepto', filtro.codepto);
            }
            if (filtro.codsec) {
                queryParams.append('codsec', filtro.codsec);
            }
            if (filtro.page) {
                queryParams.append('page', filtro.page);
            }
            if (filtro.per_page) {
                queryParams.append('per_page', filtro.per_page);
            }
        }

        // Construa a URL de consulta
        let url = 'https://app.maisrastro.com.br:8000/consultar_produtos';
        if (queryParams.toString()) {
            url += `?${queryParams.toString()}`;
        }

        const response = yield call(axios.get, url, { headers: { accept: 'application/json' } });
        if (response && response.data) {
            console.log('produtos no sagas => ', response.data)
            yield put(buscarProdutosSuccess(response.data));
        } else {
            yield put(buscarProdutosError('Failed to fetch produtos'));
        }
    } catch (error) {
        console.error('Error fetching produtos:', error);
        yield put(buscarProdutosError(error.message));
    }
}

function* buscarProdutosGiro({ filtro }) {
    try {
        const queryParams = new URLSearchParams(); // Crie um novo objeto URLSearchParams

        // Adicione cada campo do filtro como um parâmetro de consulta, se existir
        if (filtro) {
            if (filtro.codepto) {
                queryParams.append('codepto', filtro.codepto);
            }
            if (filtro.codsec) {
                queryParams.append('codsec', filtro.codsec);
            }
            if (filtro.descricao) {
                queryParams.append('descricao', filtro.descricao);
            }
        }

        // Construa a URL de consulta
        let url = 'https://app.maisrastro.com.br:8000/consultar_produtos_maior_giro';
        if (queryParams.toString()) {
            url += `?${queryParams.toString()}`;
        }

        const response = yield call(axios.get, url, { headers: { accept: 'application/json' } });
        if (response && response.data) {
            console.log('produtos com maior giro no sagas => ', response.data)
            yield put(buscarProdutosGiroSuccess(response.data));
        } else {
            yield put(buscarProdutosGiroError('Failed to fetch produtos'));
        }
    } catch (error) {
        console.error('Error fetching produtos:', error);
        yield put(buscarProdutosGiroError(error.message));
    }
}









export default all([
    takeLatest('BUSCAR_PRODUTOS_REQUEST', buscarProdutos),
    takeLatest('BUSCAR_PRODUTOS_GIRO_REQUEST', buscarProdutosGiro)

]);
