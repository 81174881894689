import React from 'react';

function MinhaConta() {
    return (
        <div className="container" style={{ minHeight: '45vh', padding: '20px' }}>
            <h1>Minha Conta</h1>
            <p>Bem-vindo à sua conta. Aqui você pode gerenciar suas informações pessoais, pedidos, e muito mais.</p>
            <ul>
                <li><a href="/">Perfil</a></li>
                <li><a href="/">Pedidos</a></li>
                <li><a href="/">Configurações</a></li>
            </ul>
        </div>
    );
}

export default MinhaConta;
