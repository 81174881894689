import React from 'react';

function Informacoes() {
    return (
        <div className="container" style={{ minHeight: '45vh', padding: '20px' }}>
            <h1>Informações</h1>
            <p>Aqui você encontra diversas informações úteis sobre nossos produtos e serviços.</p>
            <p>Se precisar de ajuda, não hesite em nos contatar.</p>
            <ul>
                <li><a href="/">FAQ</a></li>
                <li><a href="/">Suporte</a></li>
                <li><a href="/">Contato</a></li>
            </ul>
        </div>
    );
}

export default Informacoes;
