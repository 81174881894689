import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'; // Importe Link do react-router-dom
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { setProductDetailContext } from '../store/modules/context/actions';
// Estilos personalizados
const ProductCard = styled(Card)({
    borderRadius: '8px',
    height: '100%',
    transition: 'box-shadow 0.3s',
    '&:hover': {
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
    },
    '& img': {
        width: '100%',
        height: '120px',
        objectFit: 'cover',
        cursor: 'pointer',
        borderRadius: '8px 8px 0 0',
    },
    '& .MuiCardContent-root': {
        paddingBottom: '8px !important',
    },
});

function Product({ product }) {
    const dispatch = useDispatch();

    const handleProductSelect = () => {
        console.log('product no Produto', product)
        dispatch(setProductDetailContext(product));
    };

    return (
        <ProductCard>
            <CardContent>
                {/* Use o componente Link para envolver a imagem */}
                <Link to={`/product`}>
                    <img
                        src={product.imagem1}
                        alt={product.descricao}
                        style={{
                            width: '183px',
                            height: '276px',
                            objectFit: 'contain',
                            borderRadius: '4px',
                            marginBottom: '10px',
                            display: 'block',
                            margin: 'auto',
                        }}
                        onClick={handleProductSelect}
                    />
                </Link>
                <Typography variant="h6" style={{ marginTop: '10px' }}>{product.descricao}</Typography>
                <Typography variant="body2" color="textPrimary">SKU: {product.codprod}</Typography>
            </CardContent>
        </ProductCard>
    );
}

export default Product;
