import { SET_PRODUCT_DETAIL_CONTEXT, SET_FILTER_PRODUCT_CONTEXT } from "./actions";

const initialState = {
    productDetail: null,
    filterProduct: null,
    error: null,
};

export default function contextReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT_DETAIL_CONTEXT:
            return {
                ...state,
                productDetail: action.payload,
                error: null,
            };
        case SET_FILTER_PRODUCT_CONTEXT:
            return {
                ...state,
                filterProduct: action.payload,
                error: null,
            };


        default:
            return state;
    }
}