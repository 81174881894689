// reducer.js
const initialState = {
    error: null,
    produtos: []
};

export default function findReducer(state = initialState, action) {
    switch (action.type) {
        case 'BUSCAR_PRODUTOS_SUCCESS':
            console.log('produtos no reducer', action)
            return {
                ...state,
                produtos: action.produtos,
                error: null,
            };

        case 'BUSCAR_PRODUTOS_ERROR':
            return {
                ...state,
                produtos: [],
                error: action.error,
            };

        case 'SET_PRODUTO_SELECIONADO':
            return {
                ...state,
                produtoSelecionado: action.produto,
            };
        case 'BUSCAR_PRODUTOS_GIRO_SUCCESS':
            console.log('produtosGiro no reducer', action)
            return {
                ...state,
                produtosGiro: action.produtosGiro,
                error: null,
            };

        case 'BUSCAR_PRODUTOS_GIRO_ERROR':
            return {
                ...state,
                produtosGiro: [],
                error: action.error,
            };

        default:
            return state;
    }
}
