import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Header.css';

import { useDispatch } from 'react-redux';
import { buscarProdutosRequest, buscarProdutosGiroRequest } from '../store/modules/find/actions';
import { setFilterProductContext } from '../store/modules/context/actions';

function Header() {
    const [departments, setDepartments] = useState([]);
    const [isDepartmentSectionMenuOpen, setIsDepartmentSectionMenuOpen] = useState({});
    const [filterDescription, setFilterDescription] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        axios.get('https://app.maisrastro.com.br:8000/departments')
            .then(response => {
                const departmentsArray = response.data.departments;
                setDepartments(departmentsArray.map(department => ({ ...department, open: false })));
                setIsDepartmentSectionMenuOpen(departmentsArray.reduce((acc, department) => ({ ...acc, [department.code]: false }), {}));
            })
            .catch(error => {
                console.error('Error fetching departments:', error);
            });
    }, []);

    const handleDepartmentClick = (departmentCode) => {
        setIsDepartmentSectionMenuOpen(prevState => {
            const newState = {};
            Object.keys(prevState).forEach(key => {
                newState[key] = key === departmentCode ? !prevState[key] : false;
            });
            return newState;
        });

        const dropdownMenus = document.querySelectorAll('.dropdown.menu-large > .dropdown-menu');
        dropdownMenus.forEach(menu => {
            menu.classList.remove('hidden');
        });

        dispatch(buscarProdutosRequest({ codepto: departmentCode, per_page: 12 }));
        dispatch(buscarProdutosGiroRequest({ codepto: departmentCode }));
        dispatch(setFilterProductContext({ codepto: departmentCode, per_page: 12 }));
        navigate('/list');
    };

    const handleSectionClick = (departmentCode, sectionCode) => {
        dispatch(buscarProdutosRequest({ codepto: departmentCode, codsec: sectionCode, per_page: 12 }));
        dispatch(buscarProdutosGiroRequest({ codepto: departmentCode, codsec: sectionCode }));
        dispatch(setFilterProductContext({ codepto: departmentCode, codsec: sectionCode, per_page: 12 }));

        setIsDepartmentSectionMenuOpen(prevState => ({
            ...prevState,
            [departmentCode]: false
        }));

        const dropdownMenus = document.querySelectorAll('.dropdown.menu-large > .dropdown-menu');
        dropdownMenus.forEach(menu => {
            menu.classList.add('hidden');
        });

        if (isMobile) {
            const nav = document.querySelector('#cat-nav-mega');
            if (nav) {
                nav.classList.remove('in'); // Assume que a classe 'in' é usada para exibir o menu em dispositivos móveis
                nav.classList.add('collapse'); // Adiciona a classe de colapso
            }
        }

        navigate('/list');
    };

    const handleSearchByDescription = (e) => {
        e.preventDefault();
        const upperCaseDescription = filterDescription.toUpperCase();
        const isNumeric = /^\d+$/.test(upperCaseDescription); // Verifica se a entrada é numérica

        if (isNumeric) {
            dispatch(buscarProdutosRequest({ codprod: upperCaseDescription, per_page: 12 }));
        } else {
            dispatch(buscarProdutosRequest({ descricao: upperCaseDescription, per_page: 12 }));
            dispatch(buscarProdutosGiroRequest({ descricao: upperCaseDescription }));
            dispatch(setFilterProductContext({ descricao: upperCaseDescription, per_page: 12 }));
        }

        navigate('/list'); // Navegar para a página de lista após a busca
    };



    return (
        <div id="header">
            <div className="top" style={{ backgroundColor: '#343A40', padding: '4px 0' }}>
                <div className="container header-container">
                    <Link className="navbar-brand" to="/">
                        <img className='logo' src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
                    </Link>
                    <form className="side-search" onSubmit={handleSearchByDescription}>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar produto"
                                aria-describedby="basic-addon2"
                                value={filterDescription}
                                onChange={(e) => setFilterDescription(e.target.value)}
                            />
                            <button type="submit" className="input-group-addon btn-side-search">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                    <ul className="top-support" style={{ display: 'flex', listStyle: 'none', padding: 0, margin: 0, alignItems: 'center' }}>
                        <li style={{ display: 'flex', alignItems: 'center', color: '#fff', whiteSpace: 'nowrap' }}>
                            <a href="https://wa.me/5561984419139" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: '#fff', textDecoration: 'none' }}>
                                <i className="fa fa-whatsapp" style={{ color: '#fff', marginRight: '8px' }}></i>
                                <span style={{ marginRight: '8px' }}>Compre pelo whatsapp (61) 98441-9139</span>
                            </a>
                        </li>
                    </ul>


                </div>
            </div>

            <div id="believe-nav" style={{ backgroundColor: '#343A40', padding: '8px 0' }}>
                <div className="container">
                    <div className="min-marg"></div>
                    <div className="srch-form">
                        <form className="side-search">
                            <div className="input-group">
                                <input type="text" className="form-control search-wid" placeholder="Search Here" aria-describedby="basic-addon2" />
                                <a href="" className="input-group-addon btn-side-serach" id="basic-addon2"><i className="fa fa-search"></i></a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div id="cat-nav" style={{ marginTop: '0px' }}>
                <div className="container">
                    <nav className="navbar navbar-default">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#cat-nav-mega">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="cat-nav-mega">
                            <ul className="nav navbar-nav">
                                {departments.map((department) => (
                                    <li className={`dropdown menu-large ${isDepartmentSectionMenuOpen[department.code] ? 'open' : ''}`} key={department.code}>
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded={isDepartmentSectionMenuOpen[department.code]} onClick={() => handleDepartmentClick(department.code)}>
                                            {department.description} <i className="fa fa-angle-down"></i>
                                        </a>
                                        <ul className={`dropdown-menu megamenu ${isDepartmentSectionMenuOpen[department.code] ? '' : 'hidden'}`} role="menu" style={{ background: '#C0C0C0' }}>
                                            <li>
                                                <div className="">
                                                    {department.sections.map((section, index) => (
                                                        <div className="mega-sub" key={index}>
                                                            <ul>
                                                                <li><a href="#" onClick={() => handleSectionClick(department.code, section.code)}>{section.description}</a></li>
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>

            <a
                href="https://wa.me/5561984419139"
                className="whatsapp-float whatsapp-link"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fa fa-whatsapp whatsapp-icon"></i>
            </a>


        </div>
    );
}

export default Header;
