import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

import { useDispatch } from 'react-redux';
import { setProductDetailContext } from '../store/modules/context/actions';
import { buscarProdutosGiroRequest } from '../store/modules/find/actions';

function ProductSlider() {
    const [products, setProducts] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://app.maisrastro.com.br:8000/ultimos_lancamentos');
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const handleProductSelect = (product) => {
        dispatch(setProductDetailContext(product));
        dispatch(buscarProdutosGiroRequest({ codepto: product.codepto, codsec: product.codsec }));
        navigate('/product');
    };

    return (
        <div id="main-slider">
            <Slider {...settings} id="home-slider" className="slider">
                {products.map((product, index) => (
                    <div className="slider-item" key={index}>
                        <img src={product.imagem1} alt={`slide-${index}`} className="img-responsive" />
                        <div className="slider-desc">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="slide-offers-left">
                                            <div className="slide-offers-title">
                                                {product.descricao}
                                            </div>
                                            <p>Embalagem: <br />{product.embalagem}</p>
                                            <Link
                                                to="#" 
                                                onClick={(e) => {
                                                    e.preventDefault(); // Previne a navegação padrão
                                                    handleProductSelect(product);
                                                }}
                                                className="btn btn-custom"
                                            >
                                                Detalhes
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* Espaço vazio */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default ProductSlider;
