// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Wrapper from './components/Wrapper';
import Slider from './components/Slider';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import ProductDetail from './components/ProductDetail'
import ProductDetailShare from './components/ProductDetailShare';
import ProductList from './components/ProductList'
import Informacoes from './components/Informacoes';
import MinhaConta from './components/MinhaConta';
import Institucional from './components/Institucional';

function App() {
  const [selectedDepartmentCode, setSelectedDepartmentCode] = useState(null);
  return (
    <Provider store={store}>
      <Router>
        <Wrapper>
          <Header />
          <Routes>
            <Route exact path="/" element={<Slider />} />
            <Route exact path="/list" element={<ProductList selectedDepartmentCode={selectedDepartmentCode} />} />
            <Route path="/product" element={<ProductDetail />} />
            <Route path="/product-share/:codprod" element={<ProductDetailShare />} />
            <Route path="/institucional" element={<Institucional />} />
            <Route path="/minha-conta" element={<MinhaConta />} />
            <Route path="/informacoes" element={<Informacoes />} />
          </Routes>
          <Footer />
        </Wrapper>
      </Router>
    </Provider>
  );
}

export default App;
