import { all } from "redux-saga/effects";
import find from "./find/sagas";



export default function* rootSaga() {
    return yield all([
        find
    ])
}

