import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

const ProductDetailShare = () => {
    const { codprod } = useParams();
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Função para buscar os dados do produto
        const fetchProductData = async () => {
            try {
                const response = await axios.get(`https://app.maisrastro.com.br:8000/consultar_produtos?codprod=${codprod}`, {
                    headers: {
                        'accept': 'application/json'
                    }
                });
                if (response.data && response.data.resultados && response.data.resultados.length > 0) {
                    setProductData(response.data.resultados[0]);
                } else {
                    setError('Produto não encontrado');
                }
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchProductData();
    }, [codprod]);

    if (loading) return <p>Carregando...</p>;
    if (error) return <p>Erro ao carregar os dados: {error}</p>;

    return (
        <div className="container">
            <div className="jumbotron">
                <h2>{productData ? productData.descricao : "Produto não encontrado"}</h2>
                {productData && <p>SKU: {productData.codprod}</p>}
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Link className="btn btn-custom" to="/list" role="button">Voltar</Link>
                </div>
            </div>

            {productData && (
                <div className="row product-image-container">
                    <div className="col-xs-12 col-md-12">
                        <img src={productData.imagem1} alt={productData.descricao} className="img-fluid product-image" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetailShare;
