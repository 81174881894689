import { combineReducers } from "redux";

import find from "./find/reducer";
import context from "./context/reducer";


export default combineReducers({
    find,
    context
})

