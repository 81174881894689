import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import './ProductDetail.css'; // Certifique-se de que o caminho esteja correto
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';

function ProductDetail() {
    const product = useSelector(state => state?.context?.productDetail);
    const productGiro = useSelector(state => state?.find?.produtosGiro) || [];
    console.log('produto giro no detalhe => ', productGiro);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const settings = {
        dots: true,
        infinite: productGiro.length > 1,
        speed: 500,
        slidesToShow: Math.min(4, productGiro.length),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(3, productGiro.length),
                    slidesToScroll: 1,
                    infinite: productGiro.length > 1,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: Math.min(2, productGiro.length),
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: productGiro.length > 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: productGiro.length > 1
                }
            }
        ]
    };

    const handleShare = (event) => {
        event.preventDefault();
        const shareUrl = `https://catalogo.boaatacadista.com.br/product-share/${product.codprod}`;
        const text = `Confira este produto: ${product.descricao}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}%20${encodeURIComponent(shareUrl)}`;
        window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
    };


    return (
        <div className="container">
            <div className="jumbotron">
                <h2>{product ? product.descricao : "Produto não encontrado"}</h2>
                {product && <p>SKU: {product.codprod}</p>}
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Link className="btn btn-custom" to="/list" role="button">Voltar</Link>
                    {product && (
                        <Link className="btn btn-custom" to="#" onClick={handleShare} role="button">
                            <FontAwesomeIcon icon={faShareAlt} style={{ marginRight: '8px' }} />
                            Compartilhar no WhatsApp
                        </Link>
                    )}
                </div>
            </div>

            {product && (
                <div className="row product-image-container">
                    <div className="col-xs-12 col-md-12">
                        <img src={product.imagem1} alt={product.descricao} className="img-fluid product-image" />
                    </div>
                </div>
            )}

            {productGiro.length > 0 && (
                <div>
                    <h3 className="mt-5">Nossos campeões de venda nessa categoria!</h3>
                    <div className="row">
                        <Slider {...settings}>
                            {productGiro.map((item, index) => (
                                <div className="col-xs-6 col-md-3 thumbnail-container" key={index}>
                                    <a href="#" className="thumbnail">
                                        <img src={item.imagem1} alt={item.descricao} className="slider-thumbnail" />
                                        <div className="caption thumbnail-caption">
                                            <h5>{item.descricao}</h5>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProductDetail;
