// src/components/Wrapper.js
import React from 'react';

function Wrapper({ children }) {
  return (
    <div id="wrapper" className="homepage-1">
      {children}
    </div>
  );
}

export default Wrapper;
