import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Product from './Product';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Box, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { buscarProdutosRequest } from '../store/modules/find/actions';

function ProductList({ selectedDepartmentCode }) {
    const [page, setPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(12);
    const produtos = useSelector(state => state.find?.produtos);
    const filters = useSelector(state => state.context?.filterProduct);
    const produtosList = produtos?.resultados || [];
    const totalRecords = produtos?.total_registros;
    const totalPages = Math.ceil(totalRecords / productsPerPage);
    const dispatch = useDispatch();

    // Atualizar a página se for maior do que o total de páginas
    useEffect(() => {
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages);
        }
    }, [page, totalPages]);

    const handleChangePage = (event, value) => {
        setPage(value);
        const filtro = {
            ...filters, // Outros filtros existentes
            page: value, // Página atual
            per_page: productsPerPage, // Produtos por página
        };
        console.log('filtros no paginator', filtro)
        dispatch(buscarProdutosRequest(filtro)); // Chama a action com o filtro
    };

    return (
        <Paper elevation={2} sx={{ padding: 4 }}>
            <Box sx={{ backgroundColor: '#fff' }}>
                <Grid container spacing={6} style={{ marginTop: '20px' }}>
                    {produtosList.map((product, index) => (
                        <Grid item key={`${product.codprod}-${index}`} xs={12} sm={6} md={3}>
                            <Product product={product} />
                        </Grid>
                    ))}
                </Grid>

                <Stack spacing={2} justifyContent="center" sx={{ marginTop: '20px', textAlign: 'center' }}>
                    <Pagination count={totalPages} page={page} onChange={handleChangePage} size="large" />
                </Stack>
            </Box>
        </Paper>
    );
}

export default ProductList;
