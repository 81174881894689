
export function buscarDepartamentosRequest() {
  return {
    type: 'BUSCAR_DEPARTAMENTOS_REQUEST',
  };
}

export function buscarDepartamentosSuccess(filiais) {
  return {
    type: 'BUSCAR_DEPARTAMENTOS_SUCCESS',
    filiais,
  };
}

export function buscarDepartamentosError(error) {
  return {
    type: 'BUSCAR_DEPARTAMENTOS_ERROR',
    error,
  };
}

export const buscarProdutosRequest = (filtro) => {
  console.log('Filtro:', filtro); // Imprime o filtro
  return {
    type: 'BUSCAR_PRODUTOS_REQUEST',
    filtro,
  };
};


export const buscarProdutosSuccess = (produtos) => ({
  type: 'BUSCAR_PRODUTOS_SUCCESS',
  produtos,
});

export const buscarProdutosError = (error) => ({
  type: 'BUSCAR_PRODUTOS_ERROR',
  error,
});

export const setProdutoSelecionado = (produto) => ({
  type: 'SET_PRODUTO_SELECIONADO',
  produto,
});

export const buscarProdutosGiroRequest = (filtro) => {
  console.log('Filtro:', filtro); // Imprime o filtro
  return {
    type: 'BUSCAR_PRODUTOS_GIRO_REQUEST',
    filtro,
  };
};

export const buscarProdutosGiroSuccess = (  produtosGiro,
  ) => ({
  type: 'BUSCAR_PRODUTOS_GIRO_SUCCESS',
  produtosGiro,
});

export const buscarProdutosGiroError = (error) => ({
  type: 'BUSCAR_PRODUTOS_GIRO_ERROR',
  error,
});





