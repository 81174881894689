import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div id="footer">
            <div className="footer-widget" style={{ background: '#e7e7e8' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-2 col-sm-6">
                            <div className="quick-links">
                                <div className="wid-title">Institucional</div>
                                <ul>
                                    <li><a href="#">Quem somos</a></li>
                                    <li><a href="#">Carta de Valores</a></li>
                                    <li><a href="#">Responsabilidade Socioambiental</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <div className="quick-links">
                                <div className="wid-title">Políticas</div>
                                <ul>
                                    <li><a href="#">Política de Entrega</a></li>
                                    <li><a href="#">Política de Pagamento</a></li>
                                    <li><a href="#">Política de Trocas e Devoluções</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <div className="quick-links">
                                <div className="wid-title">Central de Ajuda</div>
                                <ul>
                                    <li><a href="#">Minha Conta</a></li>
                                    <li><a href="#">Meus Pedidos</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="subscribe">
                                <div className="wid-title">Formas de pagamento</div>
                                <div className="row payment-icons">
                                    <img src="/assets/images/visa.png" alt="Visa" />
                                    <img src="/assets/images/master.png" alt="Mastercard" />
                                    <img src="/assets/images/boleto.png" alt="Boleto" />
                                    <img src="/assets/images/pix.png" alt="Pix" />
                                </div>
                                <div className="wid-title">Certificado de Segurança</div>
                                <div className="row smaller-icons">
                                    <span>
                                        <img src="https://letsencrypt.org/images/letsencrypt-logo-horizontal.svg" alt="letsencrypt" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-text">
                <div className="container">
                    <p>Boa Atacadista 2024. Todos os direitos reservados</p>
                    <p>Proibida reprodução total ou parcial. Preços e estoque sujeito a alterações sem aviso prévio.</p>
                    <p>End: Av.: Marginal a via Estrutural, Chacara 123, Lote 17 - Vicente Pires - DF| CEP: 72007-155.</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
