export const SET_PRODUCT_DETAIL_CONTEXT = 'SET_PRODUCT_DETAIL_CONTEXT';
export const SET_FILTER_PRODUCT_CONTEXT = 'SET_FILTER_PRODUCT_CONTEXT';


export function setProductDetailContext(product) {
    return {
        type: SET_PRODUCT_DETAIL_CONTEXT,
        payload: product,
    };
}

export function setFilterProductContext(filtro) {
    return {
        type: SET_FILTER_PRODUCT_CONTEXT,
        payload: filtro,
    };
}

